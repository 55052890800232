<script lang="ts">
    import { useRegisterSW } from 'virtual:pwa-register/svelte'
    // @ts-ignore
    import { pwaInfo } from 'virtual:pwa-info'
    import Swal from 'sweetalert2'
    // eslint-disable-next-line no-console
    console.log(pwaInfo)

    // replaced dynamically
    const buildDate = '__DATE__'
    // replaced dyanmicaly
    const reloadSW = '__RELOAD_SW__'

    const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
        onRegisteredSW(swUrl, r) {
            // eslint-disable-next-line no-console
            console.log(`Service Worker at: ${swUrl}`)
            // @ts-ignore
            if (reloadSW === 'true') {
                r &&
                    setInterval(() => {
                        console.log('Checking for sw update')
                        r.update()
                    }, 20000 /* 20s for testing purposes */)
            } else {
                console.log(`SW Registered: ${r}`)
            }
        },
        onRegisterError(error) {
            console.log('SW registration error', error)
        },
    })

    function close() {
        offlineReady.set(false)
        needRefresh.set(false)
    }
    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
    })
    const fireToast = (title, text, type) => {
        Toast.fire({
            icon: type,
            title: title,
            text: text,
        })
    }

    $: toast = $offlineReady || $needRefresh

    const showReload = () => {
        Swal.fire({
            title: 'Info',
            text: 'Ada perubahan pada sistem, perbarui halaman anda !',
            icon: 'info',
            //   showCancelButton: true,
            confirmButtonText: 'Perbarui',
            //   cancelButtonText: 'No, keep it'
        }).then(() => {
            console.log('test')
            updateServiceWorker(true)
        })
    }
</script>

{#if toast}
    {#if $offlineReady}
        <span
            >{fireToast(
                'Sukses',
                'Aplikasi siap berfungsi secara offline',
                'success'
            )}
        </span>
    {:else}
        <span>
            {showReload()}
        </span>
    {/if}
    <!-- {#if $needRefresh}
        <button
            on:click={() => updateServiceWorker(true)}
            style="display:hidden"
        >
            Reload
        </button>
    {/if} -->
{/if}

<style>

    span {
        display: none;
    }
</style>
