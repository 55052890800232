
/**
 * @roxi/routify 2.18.8
 * File generated Wed Feb 08 2023 08:16:49 GMT+0000 (Coordinated Universal Time)
 */

export const __version = "2.18.8"
export const __timestamp = "2023-02-08T08:16:49.755Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isFallback": true,
      "path": "/_fallback",
      "component": () => import('../src/pages/_fallback.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/form",
      "id": "_form",
      "component": () => import('../src/pages/form.svelte').then(m => m.default)
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/insert",
      "id": "_insert",
      "component": () => import('../src/pages/insert.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/list",
      "id": "_list",
      "component": () => import('../src/pages/list.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/login",
      "id": "_login",
      "component": () => import('../src/pages/login.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/manual",
      "id": "_manual",
      "component": () => import('../src/pages/manual.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/profile",
      "id": "_profile",
      "component": () => import('../src/pages/profile.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/qr",
      "id": "_qr",
      "component": () => import('../src/pages/qr.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/unit",
      "id": "_unit",
      "component": () => import('../src/pages/unit.svelte').then(m => m.default)
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => import('../src/pages/_layout.svelte').then(m => m.default)
}


export const {tree, routes} = buildClientTree(_tree)

